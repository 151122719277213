import FadeIn from "../components/animation/FadeIn";
import SectionHeader from "../components/SectionHeader";
import CurriculumItems from "../components/CurriculumItems";
import { useTranslation } from "react-i18next";

const Curriculum = () => {
    const {t} = useTranslation()
    const sideArray = t("SIDEBAR.SUBJECTS", { returnObjects: true }) as Array<string>

    function handleScroll() {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0, 
          behavior: 'smooth',
        });
    }

    const workArray = t("WORK.WORKLIST", { returnObjects: true }) as Array<string>
    const educationArray = t("EDUCATION.EDUCATIONS", { returnObjects: true }) as Array<string>
    const certificateArray = t("CERTIFICATE.CERTIFICATES", { returnObjects: true }) as Array<string>

    return (
        <section className='grid md:justify-items-center gap-10 md:gap-28 lg:max-w-screen-xl pt-10 md:pt-40 px-4 md:px-10 mx-auto'>
            <div className='w-full'>
                <SectionHeader
                    subheader={`0.2 ${t("WORK.SUBTITLE")}`}
                    title={t("WORK.TITLE")}
                    paragraph={t("WORK.DESCRIPTION")}
                    center={false}
                />
                <div className='grid md:flex items-start gap-5 md:gap-20 lg:gap-40 py-5 md:pt-20 md:pb-10 lg:py-20'>
                    <div className='grid gap-14 md:w-full'>
                        <CurriculumItems data={workArray} tooling={true} />
                        <div>
                            <h3 className='font-header text-xl'>{t("EDUCATION.TITLE")}</h3>
                            <CurriculumItems data={educationArray} tooling={false} />
                        </div>
                        <div>
                            <h3 className='font-header text-xl'>{t("CERTIFICATE.TITLE")}</h3>
                            <CurriculumItems data={certificateArray} tooling={false} />
                        </div>
                    </div>
                    <div className='grid gap-5 md:gap-8'>
                        <dl className='grid gap-5'>
                            {sideArray.map((ITEM: any, index) => (
                                <FadeIn key={index} delay={index * 0.2}>
                                    <dt className='uppercase font-subheader text-sm text-slate-400'>{ITEM.TITLE}</dt>
                                    <dd className='font-header'>{ITEM.CONTENT}</dd>
                                </FadeIn>
                            ))}
                        </dl>
                        <FadeIn className='relative rounded-md border-slate-500 dark:border-white border p-4 pl-12'>
                            <div className="text-sm">
                                <FadeIn className='absolute text-4xl -left-3 top-4'>🔍</FadeIn>
                                <div className='grid gap-2'>
                                    <FadeIn delay={0.2}>
                                        <p>{t("SIDEBAR.CONTENT")}</p> 
                                    </FadeIn>
                                    <FadeIn delay={0.4}>
                                        <a className='underline hover:no-underline hover:cursor-pointer' onClick={handleScroll}>{t("SIDEBAR.LINK")}</a>
                                    </FadeIn>
                                </div>
                            </div>
                        </FadeIn>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Curriculum