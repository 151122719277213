import CurriculumItem from './CurriculumItem';
import FadeIn from './animation/FadeIn'

const CurriculumItems = (props: any) => {
    return (
        <div>
            {props.data.map((ITEM: any) => {
                return (
                    <FadeIn key={ITEM.ID} delay={ITEM.ID * 0.2}>
                        <CurriculumItem data={ITEM} tooling={props.tooling} />
                    </FadeIn>
                )            
            })}
        </div>
    )
}

export default CurriculumItems