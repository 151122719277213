import { useTranslation } from "react-i18next"
import InfoCard from "../components/InfoCard"
import SectionHeader from "../components/SectionHeader"

const Contact = () => {
    const {t} = useTranslation()

    return (
        <section className='grid justify-items-center gap-10 md:gap-28 lg:max-w-screen-xl pt-5 pb-10 md:py-20 lg:py-40 px-4 md:px-10 mx-auto'>
            <SectionHeader 
                subheader={`0.3 ${t("CONTACT.SUBTITLE")}`}
                title={t("CONTACT.TITLE")}
                paragraph={t("CONTACT.DESCRIPTION")}                
                center={true}
            />
            <InfoCard name={t("NAME")} function={t("FUNCTION")} />
        </section>
    )
}

export default Contact