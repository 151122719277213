import { useTranslation } from "react-i18next";
import FadeInUp from "../components/animation/FadeInUp";
import FadeIn from "../components/animation/FadeIn";
import { motion } from "framer-motion"

const Hero = () => {
    const {t} = useTranslation()
    const logoAmount = 12;

    function handleScroll() {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0, 
          behavior: 'smooth',
        });
    }

    return (
        <section className='lg:max-w-screen-xl py-10 md:pt-60 md:pb-40 px-4 md:px-10 mx-auto'>
            <div className='grid gap-3 max-w-3xl'>
                <FadeInUp delay={0.6}>
                    <h3 className='uppercase font-subheader text-teal-400 dark:text-teal-400'>01. {t("NAME")}</h3>
                </FadeInUp>
                <FadeInUp delay={0.8}>
                    <h1 className='text-scale-5 leading-tight font-header'>
                        <span className='text-black dark:text-white'>{t("HERO.TITLE")}</span>
                    </h1>
                </FadeInUp>
                <FadeInUp delay={1}>
                    <p className='text-scale-1'>{t("HERO.DESCRIPTION")}</p>
                </FadeInUp>
                <FadeInUp delay={1.2}>
                    <motion.button 
                        className='text-amber-500 border border-amber-500 rounded dark:shadow-xl px-5 py-3 mt-5' 
                        onClick={handleScroll}
                        whileHover={{ scale: 1.1 }} 
                        whileTap={{ scale: 0.9 }}                     
                    >
                        {t("HERO.CTA")}
                    </motion.button>
                </FadeInUp>
            </div>
            <div className='absolute hidden md:grid grid-cols-4 gap-x-4 gap-y-2 w-3/5 top-28 right-0 md:-mt-48 -mr-48'>
            {[...Array(logoAmount)].map((e, i) => 
                <FadeIn delay={i * 0.1} key={i}>
                    <span className={'text-scale-10 tracking-widest font-logo opacity-10 select-none text-orange-300 dark:text-amber-500 p-0 logo logo-' + i} key={i}>
                        L.
                    </span>
                </FadeIn>
            )}       
            </div>
      </section>
    )
}

export default Hero