import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-regular-svg-icons";
import { motion } from "framer-motion"

interface IMyProps {
    toggleTheme: Function,
    toggleMode: boolean
}

const ThemeSwitch: React.FC<IMyProps> = (props: IMyProps) => {
    return (

        <motion.button 
            className="flex flex-col justify-center shrink-0 items-center w-10 h-10 rounded-full bg-white dark:bg-slate-950 shadow-2xl cursor-pointer" 
            onClick={() => props.toggleTheme()} 
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}
        >
            {
                props.toggleMode  ? <FontAwesomeIcon icon={faSun} /> : <FontAwesomeIcon icon={faMoon} />
            }
        </motion.button>
    )
}

export default ThemeSwitch