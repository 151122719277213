import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom";

interface ContactInfo {
    icon: IconDefinition;
    action: string;
    text: string,
    tab: boolean;
}

const ContactLink = (props: ContactInfo) => {
    return (
            props.tab ? (
                <Link className='grid grid-cols-20-1 items-center w-fit gap-3 hover:underline' to={props.action} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={props.icon} className='opacity-70' /> {props.text}</Link>
            ) :  <Link className='grid grid-cols-20-1 items-center w-fit gap-3 hover:underline' to={props.action}><FontAwesomeIcon icon={props.icon} className='opacity-70' /> {props.text}</Link>
    )
}

export default ContactLink