import i18n from 'i18next';
import nlNs from './locales/nl/global.json';
import enNs from './locales/en/global.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: 'nl',
    resources: {
      nl: {
        translation: nlNs
      },
      en: {
        translation: enNs
      }
    }
  });

export default i18n;