import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import ContactLink from "./ContactLink";
import FadeIn from "../components/animation/FadeIn";
import { useTranslation } from "react-i18next";

interface User {
    name: string,
    function: string
}

const InfoCard = (props: User) => {
    const {t} = useTranslation()

    return (
        <FadeIn className='relative shadow-2xl shadow-orange-200/50 dark:bg-gradient-to-r dark:shadow-slate-900 bg-white dark:from-blue-950 dark:via-gray-900 dark:to-sky-900 border-solid dark:border-gray-800 w-full max-w-lg rounded-md p-5 md:py-7 md:px-10'>
            <div className='grid md:flex items-center justify-items-center md:justify-items-left gap-5 md:gap-10 -mt-12 md:mt-0'>
                <img className='bg-slate-800 dark:bg-white h-28 w-28 rounded-full' src='https://media.licdn.com/dms/image/D4D03AQE79REVrpgVzw/profile-displayphoto-shrink_800_800/0/1684833583652?e=1723680000&v=beta&t=wgGyTld7ZsnIpsfxMuEtUmUZryqqpaxfT8EBvkC6KMQ' />
                <div className='grid gap-5'>
                    <div className='text-center md:text-left'>
                        <h3 className='font-header text-scale-1 text-lg'>{props.name}</h3>
                        <p>{props.function}</p>
                    </div>
                    <div className='grid gap-2 justify-items-center md:justify-items-start md:text-left'>
                        <ContactLink icon={faPhone} text={t("CONTACT.NUMBER")} action={`tel: ${t("CONTACT.NUMBER")}`} tab={false} />
                        <ContactLink icon={faAt} text={t("CONTACT.EMAIL")} action={`mailto: ${t("CONTACT.EMAIL")}`} tab={false} />
                        <ContactLink icon={faLinkedin} text='LinkedIn' action={t("CONTACT.LINKEDIN")} tab={true} />
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 left-0 w-full h-1 bg-amber-500 radius'></div>
        </FadeIn>
    )
}

export default InfoCard