import { useState, Suspense } from 'react';
import TopBar from './components/TopBar';
import About from './sections/About';
import Contact from './sections/Contact';
import Curriculum from './sections/Curriculum';
import Footer from './sections/Footer'
import Hero from './sections/Hero';

function App() {
    const [darkMode, setDarkMode] = useState(true);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode)
    }

    return (
        <Suspense fallback={'Loading...'}>
        <div className={darkMode ? 'dark' : ''}>
            <div className='bg-sand dark:bg-gradient-to-b dark:from-gray-900 dark:from-10% dark:via-sky-950 dark:via-40% dark:to-gray-900 dark:to-90% font-body'>
                <div className='relative overflow-x-hidden text-slate-700 dark:text-sky-100'>
                    <TopBar toggleTheme={toggleDarkMode} toggleMode={darkMode} />
                    <Hero />
                    <About />
                    <Curriculum />
                    <Contact />
                    <Footer />
                </div>
            </div>
        </div>
        </Suspense>
    )
}

export default App;