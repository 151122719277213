import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const {t} = useTranslation()

  return (
    <footer className="bg-slate-800 p-4">
        <div className="flex justify-center items-center gap-2">
            <label className="uppercase text-sm font-subheader text-sky-100">{t("FOOTER.CONTENT")} {t("NAME")}</label>
            <a className="text-sky-100" href='https://github.com/LauraV6' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon={faGithub} />
            </a>
        </div>
    </footer>
  )
}

export default Footer