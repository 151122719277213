import FadeInUp from './animation/FadeInUp'

interface SectionHeader {
    subheader: string;
    title: string;
    paragraph: string,
    center: boolean;
}

const SectionHeader = (props: SectionHeader) => {
    return (
        <div className={`grid gap-3 max-w-3xl ${props.center ? 'text-center' : ''}`}>
            <FadeInUp>
                <h3 className='uppercase font-subheader text-teal-400'>{props.subheader}</h3>               
            </FadeInUp>
            <FadeInUp delay={0.2}>
                <h2 className='text-scale-4 leading-tight font-header'>{props.title}</h2>
            </FadeInUp>
            <FadeInUp delay={0.4}>
                <p className='text-scale-1'>{props.paragraph}</p>
            </FadeInUp>
        </div>
    )
}

export default SectionHeader