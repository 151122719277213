import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion"

const LanguagePicker = () => {
  const { i18n } = useTranslation();

  const [collapseUpper, setCollapseUpper] = useState('nl');

  const handleCollapse = () => {
    if(collapseUpper == 'nl') {
      setCollapseUpper('en');
      i18n.changeLanguage('en');
    } else {
      setCollapseUpper('nl');
      i18n.changeLanguage('nl');
    }
  };

  return (
      <motion.button  
        className='grid grid-cols-20-1 items-center gap-3 hover:text-black dark:hover:text-white' 
        onClick={handleCollapse}
        whileHover={{ scale: 1.1 }} 
        whileTap={{ scale: 0.9 }}
      >
        <FontAwesomeIcon icon={faLanguage} className='opacity-70' />{collapseUpper == 'en' ? 'Nederlands' : 'Engels'}
      </motion.button >
  )
}

export default LanguagePicker;