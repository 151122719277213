import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { motion, AnimatePresence  } from "framer-motion";

const CurriculumItem = (props: any) => {
    const [isVisible, setVisible] = useState<number | null>(null);

    const clickHandler = (id: number) => {
        if(props.tooling) {
            setVisible((prev) => {
                return prev === id ? null : id;
            });
        }
    };

    const preventClickEvent = (e: any) => {
        e.stopPropagation()
     }

    return (
        <div className='relative'>
            <div key={props.data.ID} className={`flex gap-8 text-left py-5 md:py-10 ${!props.tooling ? 'cursor-auto' : 'cursor-pointer group'}`} onClick={() => clickHandler(props.data.ID)}>
                <div className={`flex items-center justify-center flex-shrink-0 w-12 h-12 md:w-16 md:h-16 rounded-lg bg-white shadow-2xl shadow-orange-200/50 dark:shadow-slate-800 ${isVisible === props.data.ID && props.tooling ? '-rotate-6 scale-110' : ''} group-hover:-rotate-6 group-hover:scale-110 transition-transform transform ease-out duration-300`}>
                    <img className='w-8 h-8 md:w-10 md:h-10 select-none' src={props.data.IMAGE} alt={props.data.COMPANY}></img>
                </div>
                <div className='w-full'>
                    <div className='w-11/12'>
                        <h3 className='font-header text-scale-0'>{props.data.TITLE}</h3>
                        <p>{props.data.LOCATION == undefined ? props.data.COMPANY : props.data.COMPANY + ', ' + props.data.LOCATION}</p>
                        <p>{props.data.DATE}</p>
                        {
                            <AnimatePresence>
                            { 
                                props.tooling && isVisible !== props.data.ID ? (
                                    <motion.div  
                                        className='max-w-100-120'
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: "auto" }}
                                        exit={{ opacity: 0, height: 0 }}                                      
                                    >      
                                        <span className='block truncate text-slate-500 dark:text-sky-200'>{props.data.TOOLS.map((tool: any) => tool).join(', ')}</span>
                                    </motion.div > 
                                ) : undefined 
                            }
                            </AnimatePresence>
                        }                                       
                    </div>
                    {
                        <AnimatePresence>
                        {
                            isVisible === props.data.ID ? (
                                <motion.div 
                                    onClick={(e) => preventClickEvent(e)} 
                                    className='cursor-auto'
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ opacity: 1, height: "auto" }}
                                    exit={{ opacity: 0, height: 0  }}
                                >
                                    <h4 className='font-header text-lg pt-5'>Tooling</h4>
                                    <span>{props.data.TOOLS.map((tool:any) => tool).join(', ')}</span>
                                </motion.div> 
                            ) : undefined
                        }
                        </AnimatePresence>
                    }
                </div>
            </div> 
            <div className='absolute flex items-center gap-5 w-full bottom-0 h-0 z-50'>
                <hr className='w-full opacity-1 dark:opacity-20' />
                {
                    props.tooling ? (
                        <motion.button
                            className='leading-none w-7 h-7 shrink-0'
                            whileHover={{ scale: 1.1 }} 
                            whileTap={{ scale: 0.9 }}
                            onClick={() => clickHandler(props.data.ID)}
                        >
                            <FontAwesomeIcon className={"z-20 transition-transform transform ease-out duration-300 " + (isVisible === props.data.ID ? 'rotate-180' : '')} icon={faAngleDown}/>
                        </motion.button>
                    ) : undefined
                }
            </div>
        </div>
    )
}

export default CurriculumItem