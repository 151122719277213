import FadeInUp from "../components/animation/FadeInUp";
import FadeIn from "../components/animation/FadeIn";
import { useTranslation } from 'react-i18next';

const About = () => {
  const {t} = useTranslation()
  const aboutArray = t("ABOUT", { returnObjects: true }) as Array<string>
  return (
    <section className='grid justify-items-center gap-10 md:gap-28 lg:max-w-screen-xl py-10 lg:py-40 px-4 md:px-10 mx-auto'>
      {aboutArray.map((STORY: any) => (
        <FadeIn className='column flex flex-col md:flex-row justify-center items-center gap-5 md:gap-20 lg:gap-40 w-full' key={STORY.ID} delay={0.2}>
          <div className='column-content grid gap-3 w-full'>
              <h3 className='uppercase font-subheader text-amber-500'>{STORY.SUBTITLE}</h3>
              <h2 className='text-scale-3 font-header'>{STORY.TITLE}</h2>
              <p>{STORY.DESCRIPTION}</p>
              <div className='flex gap-3'>
              {
                STORY.TOOLS.map((TOOL: any, i: number) => {
                  return (
                    <FadeInUp key={i} delay={i * 0.2} className='flex items-center gap-1'>
                        <span className='text-teal-400'>▹</span>
                        <span className='font-header'>{TOOL}</span>
                    </FadeInUp>
                  )
                })
              }
              </div>
          </div>
            <FadeIn className='column-image w-full md:w-2/5' delay={0.5}>
              <img className='max-w-48 lg:max-w-xs md:float-right select-none mx-auto' src={STORY.IMG} alt={STORY.TITLE} />
            </FadeIn>
      </FadeIn>
      ))}      
    </section>
  )
}

export default About