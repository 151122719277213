import ContactLink from "./ContactLink"
import { faAt, faPhone } from "@fortawesome/free-solid-svg-icons"
import FadeIn from "./animation/FadeIn";
import ThemeSwitch from "./ThemeSwitch"
import LanguagePicker from "./LanguagePicker";
import { useTranslation } from "react-i18next";

interface IMyProps {
    toggleTheme: Function,
    toggleMode: boolean
}

const TopBar: React.FC<IMyProps> = (props: IMyProps) => {
    const {t} = useTranslation()

    return (
        <div className='relative lg:max-w-screen-xl py-6 px-4 md:px-10 mx-auto z-50'>
            <div className='grid grid-cols-1-auto md:flex md:justify-between md:items-center md:items-top gap-2 md:gap-10 lg:gap-20 text-sm md:text-base'>
                <div className='grid gap-2 md:flex md:gap-10 lg:gap-20'>
                    <FadeIn>
                        <ContactLink icon={faPhone} text={t("CONTACT.NUMBER")} action={`tel: ${t("CONTACT.NUMBER")}`} tab={false} />
                    </FadeIn>
                    <FadeIn delay={0.2}>
                        <ContactLink icon={faAt} text={t("CONTACT.EMAIL")} action={`mailto: ${t("CONTACT.EMAIL")}`} tab={false} />
                    </FadeIn>
                </div>
                <FadeIn delay={0.4} className='order-3 md:order-2 md:ml-auto'>
                    <LanguagePicker />
                </FadeIn>
                <FadeIn delay={0.6} className='md:order-3'>
                    <ThemeSwitch toggleTheme={props.toggleTheme} toggleMode={props.toggleMode} />
                </FadeIn>
            </div>
        </div>
    )
}

export default TopBar